import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/node_modules/.pnpm/next@14.0.1_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/node_modules/.pnpm/react-toastify@9.1.3_react-dom@18.2.0_react@18.2.0/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/node_modules/.pnpm/react-toastify@9.1.3_react-dom@18.2.0_react@18.2.0/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/app/globals.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/node_modules/.pnpm/next@14.0.1_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/Inter-Regular.ttf\",\"display\":\"swap\",\"variable\":\"--font-inter-regular\",\"fallback\":[\"system-ui\",\"arial\"]}],\"variableName\":\"interRegular\"}");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/components/CustomUI/hooks/AutoLogin/index.jsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/components/CustomUI/hooks/HeaderScroll/index.jsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/components/CustomUI/hooks/Logging/index.jsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/components/CustomUI/hooks/Trackers/Gtm.jsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/components/CustomUI/hooks/Trackers/Pixel.jsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/components/CustomUI/hooks/Trackers/Tawk.jsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/components/CustomUI/navigation/Announcer/index.jsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/components/CustomUI/navigation/Footer/index.jsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/components/CustomUI/navigation/Header/index.jsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/components/CustomUI/WebPopup/index.jsx");
import(/* webpackMode: "eager" */ "/home/ubuntu/frontend_temp/ornaz-desktop/src/store/provider.js")